import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  InputGroup,
  FormGroup,
  Form
} from "reactstrap";
import { Link, useNavigate } from 'react-router-dom';
import { get, post } from "../../utils/apiManager";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
import Pagination from 'react-js-pagination'
import { useForm } from "react-hook-form";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Modal from 'react-bootstrap/Modal';
import ExportCSV from "../../components/ExportCsv";
import SuccessModal from "../../components/SuccessModal";

const Index = () => {
  const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm();
  const { register: registerExcel, handleSubmit: handleSubmitExcel, formState: { errors: ErrorsExcel }, getValues: getValuesExcel, setValue: setValueExcel } = useForm();

  const [category, setCategory] = useState([])
  const Navigate = useNavigate()
  const [count, setCount] = useState()
  const [allCategories, setAllCategories] = useState([])
  const [allCategoryData, setAllCategoryData] = useState([])
  const [checkBoxData, setCheckBoxData] = useState([])
  const [isFile, setIsFile] = useState()
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 50,
    searchItem: "",
  });
  const [modalData, setModalData] = useState({
    message: "",
    show: false
  });
  const [importModel, setImportModel] = useState(false)

  useEffect(() => {
    getAllCategory()
  }, [pageData])

  const getAllCategory = () => {
    get(`/category?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${getValues("companyName")}`).then((res) => {
        setAllCategoryData(res?.allData)
        setCategory(res?.data)
        setCount(res?.pages)
        setAllCategories(res?.allCategory)
      }).catch((error) => {
        console.log(error, "erre");
      })
  }

  const changeStatus = (id, status) => {
    const Url = `${baseURL}/category/status`
    let data = { id, status: !status }
    post(Url, data).then((data) => {
      if (data?.status) {
        // toast.success(data?.message);   
        setModalData({
          message: data?.message,
          show: true
        })
        getAllCategory()
      }
      else {
        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)
    })
  }

  const handleActive = (id, status) => {
    const Url = `${baseURL}/category/sale-category-status`
    let data = { id, status: !status }
    post(Url, data).then((data) => {
      if (data?.status) {
        // toast.success(data?.message);   
        setModalData({
          message: data?.message,
          show: true
        })
        getAllCategory()
      }
      else {
        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)
    })
  }

  const deleteHandler = (id) => {
    const Url = `${baseURL}/category/delete`
    let data = { id }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t delete this category',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        post(Url, data).then((data) => {
          if (data?.status) {
            // toast.success(data?.message);
            setModalData({
              message: data?.message,
              show: true
            })
            getAllCategory()
          }
          else {
            toast.error(data?.message)
          }
        }).catch(err => {
          toast.error(err.response.data.error)
        })
      }
    });
  }

  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    });
  };

  const onSubmit = (data) => {
    getAllCategory()
  }

  const clearSearch = () => {
    setValue("companyName", "")
    getAllCategory()
  }


  const handleExport = () => {
    let exportsData = []
    allCategoryData?.map((item) => {
      exportsData.push({ "categoryName": item?.name, "subCategoryName": item?.subcategories?.name, description: item.description })
    })


    const ws = XLSX.utils.json_to_sheet(exportsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    saveAs(blob, 'category.xlsx');
  };

  const onSubmitExcelFile = (data) => {
    let Url = ""
    if (isFile == "csv") {
      Url = `${baseURL}/category/csv/add`
    } else {
      Url = `${baseURL}/category/excel/add`
    }
    let formData = new FormData();
    formData.append("excel", data?.excelFile?.[0])

    post(Url, formData, 1).then((data) => {
      if (data?.status) {
        // toast.success(data?.message);
        setModalData({
          message: data?.message,
          show: true
        })
        setImportModel(false)
        getAllCategory()
      }
      else {
        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)

    })
  }

  const checkBoxHandler = (id) => {
    let findData = checkBoxData?.find(item => item == id)
    if (findData) {
      let filterData = checkBoxData?.filter((item) => item != id)
      setCheckBoxData(filterData)
    } else {
      setCheckBoxData((prev) => [...prev, id])
    }
  }

  const activeStatusHandler = () => {
    const Url = `${baseURL}/category/multi-status`
    let data = { ids: checkBoxData, status: true }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t active this categories',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!'
    }).then((result) => {
      if (result.isConfirmed) {
        post(Url, data).then((data) => {
          if (data?.status) {
            // toast.success(data?.message);
            setModalData({
              message: data?.message,
              show: true
            })
            getAllCategory()
            setCheckBoxData([])
          }
          else {
            toast.error(data?.message)
          }
        }).catch(err => {
          toast.error(err.response.data.error)
        })
      }
    });
  }

  const deActiveStatusHandler = () => {
    const Url = `${baseURL}/category/multi-status`
    let data = { ids: checkBoxData, status: false }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t deactive this categories',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!'
    }).then((result) => {
      if (result.isConfirmed) {
        post(Url, data).then((data) => {
          if (data?.status) {
            // toast.success(data?.message);
            setModalData({
              message: data?.message,
              show: true
            })
            getAllCategory()
            setCheckBoxData([])

          }
          else {
            toast.error(data?.message)
          }
        }).catch(err => {
          toast.error(err.response.data.error)
        })
      }
    });
  }

  const allDeleteHandler = () => {
    const Url = `${baseURL}/category/multi-delete`
    let data = { ids: checkBoxData }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t deactive this categories',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        post(Url, data).then((data) => {
          if (data?.status) {
            // toast.success(data?.message);
            setModalData({
              message: data?.message,
              show: true
            })
            getAllCategory()
            setCheckBoxData([])

          }
          else {
            toast.error(data?.message)
          }
        }).catch(err => {
          toast.error(err.response.data.error)
        })
      }
    });
  }



  return (
    <>
      <Row className="mt-7" >
        <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center " >
                <div className="col-3">
                  <h3 className="mb-0" style={{ textAlign: "left" }}>Company Categories</h3>
                </div>


                <div className="col-9 text-right">
                  {/* <Button
                    color="primary"
                    onClick={() => setImportModel(true)}
                  >
                    Import
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleExport}
                  >
                    Export
                  </Button> */}
                  {
                    checkBoxData?.length > 0 &&
                    <>
                      <Button
                        color="primary"
                        onClick={activeStatusHandler}
                      >
                        All Active
                      </Button>
                      <Button
                        color="primary"
                        onClick={deActiveStatusHandler}

                      >
                        All Deactive
                      </Button>
                      <Button
                        color="primary"
                        onClick={allDeleteHandler}
                      >
                        All Delete
                      </Button>
                    </>
                  }

                  <Button
                    color="primary"
                    onClick={() => { setImportModel(true); setIsFile("excel") }}
                  >
                    Import Excel
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleExport}
                  >
                    Export Excel
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => { setImportModel(true); setIsFile("csv") }}
                  >
                    Import CSV
                  </Button>

                  <ExportCSV data={allCategoryData} />
                  <Link to="/admin/category/add">

                    <Button
                      color="primary"
                      href="#pablo"
                    >
                      Add Category
                    </Button>
                  </Link>
                </div>
              </Row>
            </CardHeader>

            <Row className="d-flex" style={{ justifyContent: "right" }}>
              <div >
                <Form onSubmit={handleSubmit(onSubmit)} >
                  <Row>


                    <Col md="6" >
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <input
                            placeholder="Searching......."
                            type="text"
                            autoComplete="new-email"
                            {...register("companyName")}
                            style={{ border: "1px solid" }}
                            className="form-control"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Button color="success" type="submit" className="d-flex" >
                      Search
                    </Button>
                    <Button color="danger" className="d-flex" onClick={clearSearch}
                      style={{ marginRight: "40px" }}
                    >
                      Clear
                    </Button>
                  </Row>
                </Form>
              </div>
            </Row>

            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Checkbox</th>
                  <th scope="col">Main Category</th>
                  <th scope="col">Icon</th>
                  {/* <th scope="col">Image</th> */}
                  <th scope="col">Sub Category</th>
                  <th scope="col">Description</th>
                  <th scope="col">is Sale Category</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  category?.length > 0 ?
                    category.map((item, index) => {
                      return (
                        < tr key={item?._id
                        }>
                          <td>
                            <input className="custom-control-input" style={{ opacity: 1, position: "revert" }} id={item?._id} type="checkbox" value={item._id} checked={checkBoxData?.includes(item._id)} onChange={() => { checkBoxHandler(item._id) }} />
                          </td>
                          <td >{item?.name}</td>

                          <td>{item?.icon ?
                            <img src={baseURL + "/" + item?.icon} width="100px" height="100px" />
                            : "-"}</td>
                          {/* <td>{item?.image ? <img src={baseURL + "/" + item?.image} width="100px" height="100px" /> : "-"}</td> */}
                          < th style={{ cursor: "pointer" }}
                          // onClick={() => Navigate(`/admin/category/sub-categories?${item?._id}`)}
                          >
                            {item?.subcategories?.length > 0 ?

                              <select className="form-control"  >
                                {
                                  item?.subcategories?.map?.((val) => {
                                    return (
                                      <option>{val?.name}</option>
                                    )
                                  })
                                }

                              </select> : 0}
                          </th>

                          <th >{item?.description ? item?.description : "-"}</th>
                          <td>{item?.isSaleStatus ? <Button color="success" type="submit" className=""
                            onClick={() => handleActive(item._id, item?.isSaleStatus)}
                          >
                            Yes
                          </Button> : <Button color="danger" type="submit" className=""
                            onClick={() => handleActive(item._id, item?.isSaleStatus)}
                          >
                            No
                          </Button>}</td>
                          <td>{item?.isActive ? <Button color="success" type="submit" className=""

                          >
                            Active
                          </Button> : <Button color="danger" type="submit" className="">
                            Deactive
                          </Button>}</td>
                          {/* <td>{item?.isActive ? "Active" : "Inactive"}</td> */}

                          <td>
                            {
                              item?.isActive ?
                                <i style={{ color: "red", width: "30px", fontSize: "20px", cursor: "pointer" }} onClick={() => changeStatus(item._id, item?.isActive)} class="fa fa-times" aria-hidden="true"></i> :
                                <i style={{ color: "green", width: "30px", fontSize: "20px", cursor: "pointer" }} onClick={() => changeStatus(item._id, item?.isActive)} class="fa fa-check" aria-hidden="true"></i>
                            }
                            <i style={{ color: "black", width: "30px", fontSize: "20px", cursor: "pointer" }} onClick={() => Navigate(`/admin/category/edit?${item._id}`)} class="fa fa-pencil-square-o" aria-hidden="true"></i>
                            <i onClick={() => deleteHandler(item._id)} class="fa fa-trash" aria-hidden="true" style={{ color: "red", width: "30px", fontSize: "20px", cursor: "pointer" }}></i>
                            <i style={{ color: "black", width: "30px", fontSize: "20px", cursor: "pointer" }} onClick={() => Navigate(`/admin/category/view?${item._id}`)} class="fa fa-eye" aria-hidden="true"></i>
                            <i
                              className="fa fa-folder"
                              style={{ color: "#666678", fontSize: "20px", cursor: "pointer" }}
                              onClick={() => Navigate(`/admin/category/sub-categories?${item?._id}`)}
                              aria-hidden="true"
                            ></i>
                          </td>
                        </tr>
                      )
                    })
                    : "No Record Found"
                }
              </tbody>
            </Table>
            {category?.length ?
              <div className="pagination-centered row" >
                <div className="col-10">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={count}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  />
                </div>
                <div className="col-1 set-drop" >
                  <select
                    className="form-control"
                    value={pageData?.perPage}
                    onChange={(event) => setPageData((prev) => ({ ...prev, perPage: event.target.value }))}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
              : ''}
          </Card>
        </Col >
        <Modal
          show={importModel}
          onHide={() => setImportModel(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Upload Categories
            </Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmitExcel(onSubmitExcelFile)}>
            <Modal.Body>
              <Col md="12">
                <FormGroup className="mb-3">

                  <label className="d-flex">Upload File  </label>
                  <InputGroup className="input-group-alternative">
                    <input
                      placeholder="Please Select File"
                      type="file"
                      {...registerExcel("excelFile", { required: true })}
                      className="form-control border"
                      accept=".xlsx, .xls"
                    />

                  </InputGroup>
                  {ErrorsExcel.excelFile && <span className="text-danger d-flex"> This field is required</span>}
                </FormGroup>
              </Col>
            </Modal.Body>

            <Modal.Footer>

              <Button onClick={() => setImportModel(false)}>Close</Button>
              <Button type="submit">Submit</Button>
            </Modal.Footer>
          </form>
        </Modal>
      </Row >
      <SuccessModal message={modalData?.message} showModal={modalData?.show} setShowModal={setModalData} />

    </>
  );
}

export default Index


