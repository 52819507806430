import React, { useEffect, useState } from "react";
import {
    FormGroup,
    Form,

    InputGroup,
    Row,
    Col,
    Button,
    Card,
    CardHeader
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { post, get } from "../../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";



const Add = () => {
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
    const Navigate = useNavigate()
    const [allSaleType, setAllSaleType] = useState([])


    useEffect(() => {
        getAllSaleType()
    }, [])

    const getAllSaleType = () => {
        get(`/sale-type`).then((res) => {
            if (res?.status) {

                setAllSaleType(res?.datas)
            }
        }).catch((error) => {
            console.log(error, "erre");
        })
    }

    const onSubmit = (data) => {
        const Url = `${baseURL}/default-template/add`
        let findData = allSaleType?.find((item) => item._id == data?.saleType)
        data.saleType = { value: findData?._id, name: findData?.name }

        post(Url, data).then((data) => {
            if (data?.status) {
                // toast.success(data?.message);
                Navigate("/admin/default-template")
            }
            else {
                toast.error(data?.message)
            }
        }).catch(err => {
            toast.error(err.response.data.error)

        })
    }


    return (
        <>

            <Row className="mt-7" >
                <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <div className="d-flex">
                                <h2 style={{ textAlign: "left", marginTop: "10px" }}>Template Add</h2>

                                <Button className='bi-trash justify-content-right' style={{
                                    position
                                        : "absolute", right: 0, marginRight: "40px"
                                }} onClick={() => Navigate(`/admin/default-template`)} color="primary">Back</Button>
                            </div>
                            <Form className="" style={{ marginTop: "40px" }} onSubmit={handleSubmit(onSubmit)}>
                                <Row>


                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <label className="d-flex">Template Name <span className="text-danger">*</span></label>

                                            <InputGroup className="input-group-alternative">


                                                <input
                                                    placeholder="Template Name"
                                                    type="text"
                                                    {...register("templateName", { required: true })}
                                                    className="form-control border"
                                                />

                                            </InputGroup>
                                            {errors.templateName && <span className="text-danger d-flex"> This field is required</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <label className="d-flex">Description <span className="text-danger">*</span></label>

                                            <InputGroup className="input-group-alternative">


                                                <textarea
                                                    placeholder="Description"
                                                    type="text"
                                                    {...register("description", { required: true })}
                                                    className="form-control border"
                                                />

                                            </InputGroup>
                                            {errors.description && <span className="text-danger d-flex"> This field is required</span>}
                                        </FormGroup>
                                    </Col>

                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <label className="d-flex">Sale Type <span className="text-danger">*</span></label>

                                            <InputGroup className="input-group-alternative">
                                                <select
                                                    id="saleType"
                                                    name="saleType"
                                                    className="block appearance-none w-full bg-white border form-control border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                                    {...register("saleType", { required: true })}

                                                >
                                                    {/* <option value="">-- Sale Type --</option> */}
                                                    {
                                                        allSaleType?.length > 0 && allSaleType?.map((val, index) => {
                                                            return (
                                                                <option value={val?._id} key={index}>{val?.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </InputGroup>
                                            {errors.saleType && <span className="text-danger d-flex"> This field is required</span>}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Button color="primary" type="submit" className="d-flex" style={{ marginTop: "10px" }} >
                                    Submit
                                </Button>

                            </Form>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
        </>
    );

}

export default Add;